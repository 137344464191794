import { modules } from "@/modules/common/modules";

export const checkModuleVars = Object.freeze([
  {
    checkTypeId: modules.FIRM_CHECK,
    listPagePath: "firma-cekleri",
    pluralName: "Firma Çekleri",
    recordName: "Firma Çeki",
    dateLabel: "VERİLİŞ TARİHİ",
    numberLabel: "ÇEK NO",
    amountLabel: "ÇEK TUTARI",
    companyLabel: "VERİLDİĞİ KİŞİ",
    companyLabelLower: "Verildiği Kişi",
    primaryCompanyGroup: "suppliers",
    firstTransactionStatusName: "Verildi",
    paymentOrReceiptByHandHeader: "KASADAN ÇEK ÖDEMESİ",
    vaultDescription: "Ödemenin çıkacağı kasa...",
    effectiveAmountLabel: "KASADAN ÇIKAN TUTAR",
    ofText: "çekin",
    checkOrNote: "çek",
    paymentOrReceipt: "ödemesidir",
    invoiceLinkPath: "alis-faturalari",
    effectiveAmountLabelInModal: "ÖDEME TUTARI"
  },
  {
    checkTypeId: modules.CUSTOMER_CHECK,
    listPagePath: "musteri-cekleri",
    pluralName: "Müşteri Çekleri",
    recordName: "Müşteri Çeki",
    dateLabel: "ALINIŞ TARİHİ",
    numberLabel: "ÇEK NO",
    amountLabel: "ÇEK TUTARI",
    companyLabel: "ALINDIĞI KİŞİ",
    companyLabelLower: "Alındığı Ki̇şi̇",
    primaryCompanyGroup: "customers",
    iconClassName: "-scale-x-100",
    firstTransactionStatusName: "Alındı",
    paymentOrReceiptByHandHeader: "KASADAN ÇEK TAHSİLATI",
    vaultDescription: "Tahsilatın işleneceği kasa...",
    effectiveAmountLabel: "KASAYA GİREN TUTAR",
    ofText: "çekin",
    checkOrNote: "çek",
    paymentOrReceipt: "tahsilatıdır",
    invoiceLinkPath: "satis-faturalari",
    effectiveAmountLabelInModal: "TAHSİLAT TUTARI"
  },
  {
    checkTypeId: modules.FIRM_NOTE,
    listPagePath: "firma-senetleri",
    pluralName: "Firma Senetleri",
    recordName: "Firma Seneti",
    dateLabel: "VERİLİŞ TARİHİ",
    numberLabel: "SENET NO",
    amountLabel: "SENET TUTARI",
    companyLabel: "VERİLDİĞİ KİŞİ",
    companyLabelLower: "Verildiği Kişi",
    primaryCompanyGroup: "suppliers",
    iconClassName: "-scale-y-100",
    firstTransactionStatusName: "Verildi",
    paymentOrReceiptByHandHeader: "KASADAN SENET ÖDEMESİ",
    vaultDescription: "Ödemenin çıkacağı kasa...",
    effectiveAmountLabel: "KASADAN ÇIKAN TUTAR",
    ofText: "senetin",
    checkOrNote: "senet",
    paymentOrReceipt: "ödemesidir",
    invoiceLinkPath: "alis-faturalari",
    effectiveAmountLabelInModal: "ÖDEME TUTARI"

  },
  {
    checkTypeId: modules.CUSTOMER_NOTE,
    listPagePath: "musteri-senetleri",
    pluralName: "Müşteri Senetleri",
    recordName: "Müşteri Seneti",
    dateLabel: "ALINIŞ TARİHİ",
    numberLabel: "SENET NO",
    amountLabel: "SENET TUTARI",
    companyLabel: "ALINDIĞI KİŞİ",
    companyLabelLower: "Alındığı Ki̇şi̇",
    primaryCompanyGroup: "customers",
    iconClassName: "-scale-100",
    firstTransactionStatusName: "Alındı",
    paymentOrReceiptByHandHeader: "KASADAN SENET TAHSİLATI",
    vaultDescription: "Tahsilatın işleneceği kasa...",
    effectiveAmountLabel: "KASAYA GİREN TUTAR",
    ofText: "senetin",
    checkOrNote: "senet",
    paymentOrReceipt: "tahsilatıdır",
    invoiceLinkPath: "satis-faturalari",
    effectiveAmountLabelInModal: "TAHSİLAT TUTARI"
  },
]);
