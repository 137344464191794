export const modules = Object.freeze({
  APP_SETTINGS: 1,
  USER_MANAGEMENT: 2,

  COMPANY: 10,
  COMPANY_GROUP: 11,
  SELLING_INVOICE: 12,
  SELLING_WAYBILL: 13,
  EXPENSE_INVOICE: 14,
  EXPENSE_WAYBILL: 26,
  CASH_MOVEMENT: 15,
  BANK_ACCOUNT: 16,
  VAULT_ACCOUNT: 17,
  CURRENCY: 25,
  PRODUCT: 18,
  PRODUCT_GROUP: 19,
  FIRM_CHECK: 20,
  CUSTOMER_CHECK: 21,
  FIRM_NOTE: 22,
  CUSTOMER_NOTE: 23,
});
