import MetotFaDollarSign from "@/components/icon-center/MetotFaDollarSign";
import MetotFaEuroSign from "@/components/icon-center/MetotFaEuroSign";
import MetotFaTurkishLiraSign from "@/components/icon-center/MetotFaTurkishLiraSign";

export const currencyArray = [
  {},
  {
    currencyId: 1,
    currencyName: "Türk Lirası",
    icon: <MetotFaTurkishLiraSign />,
    theme1: "bg-slate-50 text-sky-400 rounded",
    theme2: "bg-sky-400 text-slate-50 rounded",
  },

  {
    currencyId: 2,
    currencyName: "ABD Doları",
    icon: <MetotFaDollarSign />,
    theme1: "bg-green-50 text-green-600 rounded",
    theme2: "bg-green-600 text-green-50 rounded",
  },

  {
    currencyId: 3,
    currencyName: "Euro",
    icon: <MetotFaEuroSign />,
    theme1: "bg-blue-50 text-blue-600 rounded",
    theme2: "bg-blue-600 text-blue-50 rounded",
  },
  {
    currencyId: 4,
    currencyName: "Çin Yuanı",
    icon: <MetotFaEuroSign />,
    theme1: "bg-red-50 text-red-600 rounded",
    theme2: "bg-red-600 text-red-50 rounded",
  },
  {
    currencyId: 5,
    currencyName: "İngiliz Sterlini",
    icon: <MetotFaEuroSign />,
    theme1: "bg-red-50 text-red-600 rounded",
    theme2: "bg-red-600 text-red-50 rounded",
  },
];
