import clsx from "clsx";
import { currencyArray } from "./currency-array";

export function CurrencyIcon({ currencyId, className, theme = 0 }) {
  const c = currencyArray[currencyId || 1];

  const themeClass = c["theme" + theme];

  return <span className={clsx(className, themeClass, "rounded-full")}>{c.icon}</span>;
}

export function CurrencyIconWithName({ currencyId, className, theme = 0 }) {
  const c = currencyArray[currencyId || 1];

  const themeClass = c["theme" + theme];

  return (
    <div className={clsx("flex items-center gap-1", className)}>
      <span className={clsx(themeClass, "rounded-full")}>{c.icon}</span>
      {c.currencyName}
    </div>
  );
}
